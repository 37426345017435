import { IContentfulResponse } from '@smu-chile/pkg-unimarc-hooks'
import { levenshteinDistance } from 'shared/helpers/levenshteinDistance'

interface GetBannerLegalProps {
  bannerLegal: IContentfulResponse
  search: string
}

export const getBannerLegal = ({
  bannerLegal,
  search
}: GetBannerLegalProps): object => {
  const result =
    Array.isArray(bannerLegal.items) &&
    bannerLegal.items.find((banner) => {
      const listWords =
        typeof banner?.fields?.searchReferenceWords === 'string'
          ? banner?.fields?.searchReferenceWords?.toString()?.split(',')
          : []

      return (
        Array.isArray(listWords) &&
        listWords.some((word) => {
          if (typeof word === 'string' && typeof search === 'string') {
            return (
              levenshteinDistance({
                str1: word.replace(/\s/g, ''),
                str2: search.replace(/\s/g, '')
              }) <= (banner?.fields?.distanceToMatchSearchWords || 2)
            )
          }
          return false
        })
      )
    })
  const imgDesktop = bannerLegal?.includes?.Asset?.find((img) => {
    return img.sys.id === result?.fields?.imageDesktop['sys']['id']
  })
  const imgMobile = bannerLegal?.includes?.Asset?.find((img) => {
    return img.sys.id === result?.fields?.imageMobile['sys']['id']
  })

  return {
    ...bannerLegal,
    includes: {
      Asset: [imgDesktop, imgMobile]
    },
    items: [result]
  }
}
