import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  BackboneShelves,
  Button,
  ButtonStatus,
  CatchShelfRefProps,
  Column,
  Container,
  Row,
  ShelfCarousel,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  getCouponName,
  initProductImpression,
  isValidArrayWithData,
  postProductsByIdentifierV3,
  ProductTagContext,
  tagGenericCouponDetailError,
  tagNoProductsCouponError,
  useEvents,
  useMobile,
  useUpdateFromShelf
} from '@smu-chile/pkg-unimarc-hooks'
import { ICouponObject } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICoupon'
import {
  useProdutsCarousel,
  useShelvesData
} from 'components/ProductsCarousel/helpers/useProdutsCarousel'
import { CouponDetailHeader } from '../CouponDetailHeader/CouponDetailHeader'
import { CouponDetailLegal } from '../CouponDetailLegal/CouponDetailLegal'
import { CouponDetailMessage } from '../CouponDetailMessage/CouponDetailMessage'
import { CouponDetailProducts } from '../CouponDetailProducts/CouponDetailProducts'
import Link from 'next/link'
import { mapProductsToOldSchema } from 'shared/helpers/mapProducts/mapProductsToOldSchema'
import { mapProductsForCoupons } from 'shared/helpers/coupons'
import { REACT_QUERY_GENERAL } from '../../../shared/constants/reactQuery'
import { IProductsByIdentifierV3Response } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IProductsByIdentifierV3'
import styles from './CouponDetailContent.module.css'

const CouponLabelMap: Record<string, string> = {
  USED: 'Utilizado',
  UNACTIVATED: 'Activa tu cupón',
  ACTIVATED: 'Agregar'
}

const CouponStatusMap: Record<string, number> = {
  UNACTIVATED: 1,
  ACTIVATED: 2,
  USED: 3,
  undefined: 4
}

const CouponButtonStatusMap: Record<number, ButtonStatus | null> = {
  0: 'disabled',
  1: 'disabled',
  2: 'initial',
  3: 'disabled'
}

export interface CouponDetailContentProps {
  activeLoading?: boolean
  coupon?: ICouponObject
  selectedFilter?: string
  onActive?: () => void
  onShelfCatch?: (payload: unknown) => void
}

export const CouponDetailContent = ({
  activeLoading = false,
  coupon,
  selectedFilter,
  onActive,
  onShelfCatch
}: CouponDetailContentProps) => {
  const productsRef = useRef<unknown[]>([])
  const [isReadyToTag, setIsReadyToTag] = useState(false)
  const [errorProducts, setErrorProducts] = useState(false)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(true)
  const [productsResponse, setProductsResponse] =
    useState<IProductsByIdentifierV3Response>(null)

  const { typeOffer, status } = coupon
  const buttonLabel = CouponLabelMap[status]
  const buttonStatus = isOrderFormLoading
    ? 'loading'
    : CouponButtonStatusMap[status]
  const isTotalTicket = typeOffer == '1'

  const products = useMemo(() => {
    if (productsResponse && productsResponse.availableProducts) {
      return [
        ...(productsResponse?.availableProducts?.map((product) => {
          return {
            ...product,
            coupon: {
              ...product.coupon,
              status: CouponStatusMap[status]
            }
          }
        }) || []),
        ...(productsResponse?.notAvailableProducts || [])
      ]
    }

    return []
  }, [productsResponse])

  const getTagContext = (
    initialQuantity: number,
    finalQuantity: number,
    isChangeEvent?: boolean
  ): ProductTagContext | undefined => {
    if (isChangeEvent) return 'ingresar'
    if (initialQuantity === 0 && finalQuantity === 1) return 'agregar'
    if (finalQuantity < initialQuantity) return '-'
    if (finalQuantity > initialQuantity) return '+'
    return undefined
  }

  const {
    editTempOrderForm,
    handleAdd,
    handleOnClick,
    handleRemove,
    handleChange,
    shelvesData
  } = useUpdateFromShelf({
    products,
    isOrderFormLoading,
    tagProductQuantityChange: true,
    getContext: getTagContext,
    getListName: () => {
      return (
        'Club Ahorro - ' +
        getCouponName({
          description: coupon.description,
          subtitle: coupon.subTitle,
          title: coupon.title
        })
      )
    },
    getListId: () => {
      return coupon.id
    }
  })

  const shelvesDataMapped = shelvesData.map((item) => {
    if (buttonStatus === 'disabled') item.buttonStatus = buttonStatus
    return item
  })

  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const { dataPromotions } = useProdutsCarousel({ isMobile })
  const productsMemo = useShelvesData(
    shelvesDataMapped,
    dataPromotions,
    isMobile
  )

  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const fetchProducts = async () => {
    try {
      setErrorProducts(false)
      setLoadingProducts(true)
      if (coupon?.productIds.length > 0) {
        // response sin data mockeada (con la respuesta del servicio)
        const response = await postProductsByIdentifierV3({
          field: 'id',
          headers: {},
          values: coupon?.productIds,
          reactQuery: {
            ...REACT_QUERY_GENERAL
          }
        })
        // response con data mockeada
        // const response = productsByIdentifierV3
        const productsOldFormat = mapProductsToOldSchema(
          response?.availableProducts || []
        )

        const productsOldFormatForCoupons = mapProductsForCoupons(
          productsOldFormat,
          response?.availableProducts
        )
        response.availableProducts = productsOldFormatForCoupons
        if (isValidArrayWithData(productsOldFormatForCoupons))
          setProductsResponse(response)
        setErrorProducts(response?.status !== 200)
      } else {
        setProductsResponse(null)
      }
    } catch (e) {
      setErrorProducts(true)
    }

    setLoadingProducts(false)
  }

  const handleCouponErrorReload = () => {
    fetchProducts()
  }

  const handleCouponStampActive = () => {
    if (onActive) {
      onActive()
    }
  }

  const handleQuantityAdd = (payload: { itemId: string; source: string }) => {
    if (status === 'ACTIVATED') {
      handleAdd(payload)
    }
  }

  const handleQuantityChange = (
    e?: React.ChangeEvent<HTMLInputElement>,
    payload?: { itemId: string; source: string }
  ) => {
    if (status === 'ACTIVATED') {
      handleChange(e, payload)
    }
  }

  const handleQuantityClick = (payload: { itemId: string; source: string }) => {
    if (status === 'UNACTIVATED') {
      handleCouponStampActive()
    }
    handleOnClick(payload)
  }

  const handleQuantityRemove = (payload: {
    itemId: string
    source: string
  }) => {
    handleRemove(payload)
  }

  const handleShelfCarouselCatch = (payload: CatchShelfRefProps) => {
    productsRef.current[payload.index - 1] = {
      product: payload.shelf,
      index: payload.index,
      ref: payload.ref
    }
    if (productsRef.current.length === products.length) setIsReadyToTag(true)
    if (onShelfCatch) {
      onShelfCatch(payload)
    }
  }

  useEffect(() => {
    if (status != 'ACTIVATED')
      return () => {
        return
      }
    const removeEventListener = initProductImpression({
      shelves: productsRef.current,
      coupon: coupon,
      isSearch: false
    })

    return removeEventListener
  }, [isReadyToTag, status])

  const showGenericError = errorProducts && !loadingProducts
  const showNoProductsError =
    !loadingProducts &&
    !errorProducts &&
    !isTotalTicket &&
    shelvesData &&
    shelvesData.length < 1

  useEffect(() => {
    if (showNoProductsError) {
      tagGenericCouponDetailError({
        id: coupon.id,
        name: getCouponName({
          title: coupon.title,
          description: coupon.description,
          subtitle: coupon.subTitle
        }),
        filter: selectedFilter
      })
    }
  }, [showGenericError])

  useEffect(() => {
    if (showNoProductsError) {
      tagNoProductsCouponError({
        id: coupon.id,
        name: getCouponName({
          title: coupon.title,
          description: coupon.description,
          subtitle: coupon.subTitle
        })
      })
    }
  }, [showNoProductsError])

  useEffect(() => {
    !productsResponse && fetchProducts()
  }, [coupon])

  return (
    <>
      <BigScreen>
        <Row alignItems='start'>
          <Text
            fontSize='xl'
            fontWeight='semibold'
          >
            Productos del cupón
          </Text>
        </Row>
      </BigScreen>

      <Row padding='24px 0'>
        <CouponDetailHeader
          activeLoading={activeLoading}
          coupon={coupon}
          onActive={handleCouponStampActive}
          selectedFilter={selectedFilter}
        />
      </Row>

      {loadingProducts && (
        <Container padding='11px 0'>
          <BackboneShelves
            shelvesQuantityDesktop={5}
            shelvesQuantityMobile={4}
          />
        </Container>
      )}

      {showGenericError && (
        <Row>
          <Column
            alignItems='center'
            justifyContent='center'
            minHeight='40vh'
          >
            <CouponDetailMessage
              action={
                <Button
                  borderRadius='24px'
                  label='Volver a cargar'
                  onClick={handleCouponErrorReload}
                  size='small'
                />
              }
              subtitle='Inténtalo nuevamente más tarde. '
              title='Lo sentimos, no hemos podido cargar tu cupón.'
            />
          </Column>
        </Row>
      )}

      {showNoProductsError && (
        <Row justifyContent='center'>
          <Column maxWidth='600px'>
            <CouponDetailMessage
              subtitle={
                <>
                  Puedes usar este cupón para compras en todas&nbsp;
                  <BigScreen>
                    <Text
                      fontSize='xl'
                      type='b'
                    >
                      nuestras tiendas.
                    </Text>
                  </BigScreen>
                  <SmallScreen>
                    <Text
                      fontSize='sm'
                      isFlex={false}
                      type='b'
                    >
                      nuestras tiendas.
                    </Text>
                  </SmallScreen>
                </>
              }
              title='Lo sentimos, no hay productos disponibles para este cupón en la web.'
            />
          </Column>
        </Row>
      )}

      {!loadingProducts && shelvesData && shelvesData.length > 0 && (
        <Row>
          <BigScreen>
            <ShelfCarousel
              buttonLabel={buttonLabel}
              carouselProps={{ showDots: false }}
              catchShelfRef={handleShelfCarouselCatch}
              containerProps={{
                gap: 16
              }}
              customButtonGroupProps={{ customClass: styles.arrows }}
              handleOnClick={handleQuantityClick}
              isMobile={false}
              items={productsMemo}
              itemsToShow={5}
              linkWrapper={Link}
              quantityButtonProps={{
                handleAdd: handleQuantityAdd,
                handleChange: handleQuantityChange,
                handleRemove: handleQuantityRemove
              }}
              quantityButtonStyles={{
                fontSize: 'md',
                margin: '0px',
                maxHeight: '30px',
                maxWidth: '163px',
                sizeCircle: 22
              }}
              showArrows
              showCouponButton={true}
            />
          </BigScreen>

          <SmallScreen>
            <CouponDetailProducts
              buttonLabel={buttonLabel}
              buttonStatus={buttonStatus}
              isLoading={isOrderFormLoading}
              items={productsMemo}
              onClickAdd={handleQuantityAdd}
              onClickItem={handleQuantityClick}
              onClickRemove={handleQuantityRemove}
            />
          </SmallScreen>
        </Row>
      )}

      {!errorProducts && (
        <Row>
          <Column padding='24px 8px'>
            <CouponDetailLegal
              couponEndDate={coupon?.endDate}
              couponLegal={coupon.legal}
              couponStartDate={coupon?.startDate}
            />
          </Column>
        </Row>
      )}
    </>
  )
}
