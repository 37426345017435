import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  CustomPortal,
  MembershipModal
} from '@smu-chile/pkg-unimarc-components'
import {
  Cookies,
  datalayerSimpleEvent,
  getCurrentDomain,
  trigger,
  useEvents,
  useMembershipData,
  useMobile,
  useUserWithMembership
} from '@smu-chile/pkg-unimarc-hooks'
import { IMembershipResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMembership'
import { ToastWrapper } from 'components/ToastWrapper'
import {
  FIVE_MINUTES_OUTDATED_INFO,
  MEMBERSHIP_STATUS
} from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import { dataLayerMembership } from './helpers/dataLayerMembership'

export const MembershipWrapper = () => {
  const domain = getCurrentDomain()
  const { isMobile } = useMobile()
  const router = useRouter()
  const [runReload, setRunReload] = useState(false)
  const [membershipResponse, setMembershipResponse] =
    useState<IMembershipResponse>()
  const [eventContext, setEventContext] = useState('')

  const { data, refetch } = useUserWithMembership({
    staleTime: FIVE_MINUTES_OUTDATED_INFO
  })

  useEvents({
    eventType: 'membershipToast',
    callBack: ({ detail }) => {
      setMembershipResponse(detail)
      if (detail?.success && router?.pathname === '/club-unimarc') {
        Cookies.set(
          'showMembershipToast',
          JSON.stringify({ ...detail, animationFinished: true }),
          {
            domain
          }
        )
        setRunReload(true)
      }
    }
  })

  useEvents({
    eventType: 'openMembershipModal',
    callBack: ({ detail }) => {
      if (data?.membership === MEMBERSHIP_STATUS.ACTIVE) {
        router.push(process.env.NEXT_PUBLIC_MEMBERSHIP_URL)
      } else {
        setEventContext(detail?.eventContext)
        if (detail?.eventContext) {
          const dataLayer = dataLayerMembership({
            status: data?.membership,
            eventContext: detail?.eventContext,
            planSelected,
            type: 'open'
          })
          datalayerSimpleEvent({ ...dataLayer })
        }
        handleOpenMembershipModal()
      }
    }
  })

  const handleCloseToastMessage = () => {
    trigger({
      eventType: 'membershipToast',
      data: {
        showTooltip: false
      }
    })

    if (membershipResponse?.success) {
      Cookies.remove('showMembershipToast', {
        domain
      })
    }
  }

  const {
    animationFiles,
    availableCards,
    coconutImg,
    dataHasFailed,
    handleChooseCard,
    handleCloseMembershipModal,
    handleGetMembership,
    handleOpenMembershipModal,
    handleRefreshData,
    handleSelectPaymentMethod,
    handleSelectPlan,
    handleSetCard,
    handleStopAnimation,
    handleToggleTerms,
    handleToggleModalCards,
    handleToggleModalListPaymentMethods,
    isLoading,
    isLoadingModalCards,
    isLoadingTrialTime,
    isLoadingANewCard,
    labelBtn,
    membershipData,
    membershipStatus,
    modalTitle,
    openMembershipModal,
    openModalCards,
    openModalListPaymentMethods,
    planSelected,
    selectedCard,
    selectedTemporalCard,
    toggleTerms
  } = useMembershipData()

  const handleMembership = () => {
    if (eventContext) {
      const dataLayer = dataLayerMembership({
        status: data?.membership,
        eventContext,
        planSelected,
        type: 'button'
      })
      datalayerSimpleEvent({ ...dataLayer })
    }
    handleGetMembership()
  }

  useEffect(() => {
    if (!isLoadingTrialTime) {
      setMembershipResponse({
        ...membershipResponse,
        animationFinished: true
      })
      if (runReload) {
        window.scrollTo(0, 0)
        router.reload()
      } else {
        refetch()
      }
    }
  }, [isLoadingTrialTime])

  useEffect(() => {
    const showToast = JSON.parse(Cookies.get('showMembershipToast') ?? '{}')
    if (showToast?.showTooltip) {
      setMembershipResponse(showToast)
    }
  }, [])

  return (
    <>
      {membershipResponse?.animationFinished &&
        membershipResponse?.showTooltip &&
        !runReload && (
          <CustomPortal
            rootId={membershipResponse?.success ? '' : 'portal-membership'}
          >
            <ToastWrapper
              errorCode={membershipResponse?.errorCode}
              eventContext={eventContext}
              membershipStatus={data?.membership}
              message={membershipResponse?.message}
              onClose={handleCloseToastMessage}
              planSelected={planSelected}
              success={membershipResponse?.success}
            />
          </CustomPortal>
        )}
      {openMembershipModal && (
        <MembershipModal
          checkJson={animationFiles?.checkFile}
          coconutImg={coconutImg}
          dataHasFailed={dataHasFailed}
          diamondJson={animationFiles?.diamondFile}
          handleClose={handleCloseMembershipModal}
          handleGetMembership={handleMembership}
          handleRefreshData={handleRefreshData}
          handleSelectPlan={handleSelectPlan}
          handleStopAnimation={handleStopAnimation}
          handleToggleTerms={handleToggleTerms}
          isLoading={isLoading}
          isLoadingTrialTime={isLoadingTrialTime}
          isOpenModalCards={openModalCards}
          isOpenModalPaymentMethods={openModalListPaymentMethods}
          labelBtn={labelBtn}
          membershipData={membershipData}
          membershipResponse={membershipResponse}
          membershipStatus={membershipStatus}
          modalListNewPaymentsProps={{
            bannerAvailablePaymentMethods:
              membershipData?.modalAvailablePaymentMethods
                ?.bannerAvailablePaymentMethods,
            handleGoBack: handleToggleModalCards,
            handleSelectPaymentMethod: handleSelectPaymentMethod,
            handleToggle:
              !openModalListPaymentMethods || isMobile
                ? handleToggleModalListPaymentMethods
                : handleCloseMembershipModal,
            paymentMethods: membershipData?.modalAvailablePaymentMethods,
            total: planSelected?.subtitulo2 as string,
            isLoading: isLoadingANewCard
          }}
          modalListPaymentsCardsProps={{
            cardsToList: availableCards,
            handleGoBack: handleToggleModalCards,
            handleContinue: handleSetCard,
            handleSelectCard: handleChooseCard,
            handleToggle:
              !openModalCards || isMobile
                ? handleToggleModalCards
                : handleCloseMembershipModal,
            handleTooglePaymentMetods: handleToggleModalListPaymentMethods,
            isLoading: isLoadingModalCards,
            selectedCard: selectedTemporalCard,
            total: planSelected?.subtitulo2 as string
          }}
          modalTitle={modalTitle}
          planSelected={planSelected}
          rocketJson={animationFiles?.rocketFile}
          selectedCard={selectedCard}
          toggleTerms={toggleTerms}
        />
      )}
    </>
  )
}
