import {
  HandleClickCategoryPill,
  Place
} from '@smu-chile/pkg-unimarc-components'
import { NextRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const useFilterCategories = (
  router: NextRouter,
  categoriesId?: string
) => {
  const [place, setPlace] = useState<Place>()
  const [currentCategory, setCurrentCategory] = useState('')
  const [treeValue, setTreeValue] = useState(categoriesId)

  const updateRouterPath = (newURLRouterPath: URL) => {
    router.push(newURLRouterPath.href, undefined, { shallow: true })
  }

  const handleClickCategoryPill: HandleClickCategoryPill = ({
    categories,
    slugValue
  }) => {
    const newURLRouterPath = new URL(location.href)

    newURLRouterPath.searchParams.delete('page')
    newURLRouterPath.searchParams.delete('orderBy')

    if (place === Place.PLP) {
      newURLRouterPath.pathname = `/category/${slugValue}`
      if (categories !== undefined && categories !== '') {
        setTreeValue(slugValue)
      }
    } else {
      newURLRouterPath.searchParams.delete('categories')
      if (categories !== undefined && categories !== '') {
        newURLRouterPath.searchParams.set('categories', slugValue)
      }
    }
    updateRouterPath(newURLRouterPath)
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // optional for smooth scrolling
    })
  }
  useEffect(() => {
    if (router?.asPath?.includes('/ofertas/')) setPlace(Place.COLLECTIONS)
    if (router?.asPath?.includes('/category/')) setPlace(Place.PLP)
    if (router?.asPath?.includes('/search')) setPlace(Place.SRP)
    const validCategories = router?.query?.categories
      ? decodeURIComponent(router?.query?.categories as string)
      : undefined
    setCurrentCategory(validCategories)
  }, [router?.asPath])

  return {
    currentCategory,
    handleClickCategoryPill,
    place,
    treeValue
  }
}
