import { useState, useEffect, useCallback, useRef } from 'react'
import isEqual from 'lodash.isequal'
import { NextRouter } from 'next/router'
import { filterIconDictionary } from 'shared/helpers/filterIconDictionary'
import {
  FacetsFilterV2 as FacetsData,
  ICategory,
  PriceRange
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IIntelligenceSearch'
import {
  GetData,
  createLead,
  adapterFilterData,
  findIconName,
  findId,
  isFilterActive,
  getSelectedFilters,
  updateQueryParamsFilters,
  activeFiltering,
  GetDataStatus,
  transformFacetsDataString,
  isFacetsDataEmpty,
  removeDuplicatesFromObject,
  removeInvalidItems,
  getQueryParamValue,
  ModifiedSelectedFiltersReturnType,
  formatFiltersFromQueryParams,
  processPriceAndPromoFacets
} from './helpers'
import {
  isObjectEmpty,
  isValidArrayWithData,
  sleep,
  useEvents
} from '@smu-chile/pkg-unimarc-hooks'
import { paramsToCheck } from './constants'
import {
  TFacetData,
  TFacetsDataWithCategoriesAndPrices,
  TFormatedData,
  TGetFilter,
  TUseFilterData
} from './types'
import { IPostFacetsResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IPostFacets'

export const useFilter = (router: NextRouter): TUseFilterData => {
  // initial and format data
  const [initialData, setInitialData] = useState<
    IPostFacetsResponse | Record<string, never>
  >({})
  const [formatData, setFormatData] = useState<TFormatedData[] | []>([])
  const [currentSelectedFilters, setCurrentSelectedFilters] = useState<
    ModifiedSelectedFiltersReturnType | Record<string, never>
  >({})
  const isSearch = router?.asPath?.includes('search')
  const validatedRouterAsPath = isSearch
    ? router?.asPath
    : router?.asPath?.split('?')[0]
  const currentRouterPath = useRef(validatedRouterAsPath || '')
  // filters and selections
  const [filterQuery, setFilterQuery] = useState<Record<string, string[]>>({})
  const [applyFilters, setApplyFilters] = useState<
    ModifiedSelectedFiltersReturnType | Record<string, never>
  >({})
  // with this state we re rerun the request to search intelligence
  const [arraySelectedFilters, setArraySelectedFilters] = useState<GetData[]>(
    []
  )
  const [filtersQuantity, setFiltersQuantity] = useState(0)

  // UI
  const [enableButton, setEnableButton] = useState(false)
  const [clearAll, setClearAll] = useState(false)
  const [clearImmediately, setClearImmediately] = useState(false)
  const [hasFilters, setHasFilters] = useState(false)
  const [withoutProducts, setWithoutProducts] = useState(false)
  const previousActiveFacetsRef = useRef<string[]>([])
  const previousFormatedDataRef = useRef<TFormatedData[] | []>([])
  const previousInitialDataRef = useRef<
    IPostFacetsResponse | Record<string, never>
  >({})
  const priceRangeValueRef = useRef<PriceRange | Record<string, never>>({})
  const processInitialDataRef = useRef(0)

  // we hear this event when user click in some category from the menu
  // specifically in CategoryLink component (pkgc) to reset all facets data
  useEvents({
    eventType: 'refetchFacets',
    callBack: () => {
      resetAllStates()
    }
  })

  // compares the provided data with the initial data, updates initial data if they differ.
  const compareAndSetInitialData = (data: IPostFacetsResponse) => {
    if (!isEqual(data, initialData)) {
      const cleanedData = removeInvalidItems(data)
      const hasDataFacets = Object.values(cleanedData).some((item) => {
        return isValidArrayWithData(item)
      })
      if (hasDataFacets) previousInitialDataRef.current = cleanedData
      const validData = hasDataFacets
        ? cleanedData
        : previousInitialDataRef.current

      setInitialData(validData)
    }
  }

  const getFilter = useCallback(
    (property: string, title = ''): TFormatedData | Record<string, never> => {
      if (isValidArrayWithData(formatData)) {
        const formatedDataArray = formatData as TFormatedData[]
        const foundFilter = formatedDataArray.filter(
          (filter: TFormatedData) => {
            return filter.property === property
          }
        )
        return { ...foundFilter[0], title }
      }
      return {}
    },
    [formatData]
  )

  const facets = {
    brands: getFilter('brands', 'Marcas') as TGetFilter,
    container: getFilter('container', 'Tipo de envase') as TGetFilter,
    foodWorld: getFilter('foodWorld', 'Estilos de alimentación') as TGetFilter,
    priceRange: getFilter('priceRange', 'Precio') as TGetFilter,
    promotionsOnly: getFilter('promotionsOnly', 'Promociones') as TGetFilter,
    volume: getFilter('volume', 'Formato') as TGetFilter,
    warningStamps: getFilter(
      'warningStamps',
      'Sellos de advertencia'
    ) as TGetFilter
  }

  const resetAllStates = () => {
    // clearAll to invoke updateQueryParamsFilters and reset url
    setClearAll(true)
    // clear the formated initialData
    setFormatData([])
    // clear the selected filters
    setArraySelectedFilters([])
    // enables apply filters button setEnableButton(true)
    // reset any url facet filter found or applyFilters state with data
    setFilterQuery({})
    // reset any applied filters existing
    setApplyFilters({})
    // reset priceRangeRef
    processInitialDataRef.current = 0
  }

  // processes and formats initial filter data, updating application
  // state based on URL changes and filter queries
  const processInitialData = (
    filterQuery: Record<string, string[]>,
    executionTimes: number
  ) => {
    // Check if 'initialData' is an object and has valid keys (non-empty array)
    if (
      typeof initialData === 'object' &&
      isValidArrayWithData(Object.keys(initialData))
    ) {
      // Destructure 'asPath' from 'router' object, or default
      // to an empty object if 'router' is undefined
      const { asPath } = router || {}
      const pathName = isSearch ? asPath : asPath.split('?')[0]
      // Cast 'initialData' to 'FacetsData' type for safer access
      const initialFacetData = initialData as FacetsData
      const routerPathNameRegex = new RegExp(pathName.split('/')[2])

      // Check if the current path is different from the previous path
      // to reset all data and filters and is not search Page
      if (
        pathName !== currentRouterPath.current &&
        !routerPathNameRegex.test(currentRouterPath.current) &&
        !isSearch
      ) {
        resetAllStates()
        // set the current path to state
        currentRouterPath.current = pathName
      }

      // Check if the current path is different from the previous path
      // but the same category and without queryParams
      // having clearAll true
      // to reset it with setClearAll(false) to add the queryParams to
      // the url when click on apply filters button
      if (
        pathName !== currentRouterPath.current &&
        routerPathNameRegex.test(currentRouterPath.current) &&
        !router.asPath.split('?')[1] &&
        !isSearch &&
        clearAll
      ) {
        setClearAll(false)
      }

      // Check if the 'q' query parameter value has changed
      // between the current and previous path ensuring that
      // we are in search page
      if (
        getQueryParamValue(pathName, 'q') !==
          getQueryParamValue(currentRouterPath.current, 'q') &&
        isSearch &&
        !clearAll
      ) {
        resetAllStates()
        // set the current path to state
        currentRouterPath.current = pathName
      }

      // Map over the keys of 'initialFacetData', adapting and formatting the data for each key
      const formatedData = Object.keys(initialFacetData).map(
        (key: keyof FacetsData) => {
          // Use 'adapterFilterData' function to adapt and format the data for the current key
          return {
            ...adapterFilterData(
              key,
              initialFacetData[key],
              arraySelectedFilters,
              filterQuery
            )
          }
        }
      )
      const hasEmptyFacetsData = isFacetsDataEmpty(formatedData)
      // if isFacetsDataEmpty falsy then add formatedData to Ref
      // for data persistency
      if (!hasEmptyFacetsData) previousFormatedDataRef.current = formatedData
      const validFormatedData = hasEmptyFacetsData
        ? previousFormatedDataRef.current
        : formatedData

      // Update the 'formatData' state with the newly formatted data
      setFormatData(validFormatedData)
      const previousActiveFilterNames = validFormatedData.flatMap((filter) => {
        return filter.data
          .filter((item) => {
            return item.status === 'active'
          })
          .map((filterData) => {
            return filterData.name
          })
      })
      previousActiveFacetsRef.current = previousActiveFilterNames

      // save the initial from value of priceRange to compare it later
      // when useEffect execute less or equal 3 and when we want to
      // reset facets with clearAll === true
      if (executionTimes <= 3 || clearAll) {
        const findPriceRangeFacet = initialFacetData?.priceRange

        priceRangeValueRef.current = {
          from: findPriceRangeFacet?.from,
          to: findPriceRangeFacet?.to
        }
      }
      // Update the 'filtersQuantity' state with the number of keys in 'validFormatedData'
      setFiltersQuantity(Object.keys(validFormatedData).length)
    }
  }
  // handles the application of processed filters, updating
  // URL query parameters, state, and selected filters
  const handleProcessedFilters = (
    processedFilters: Record<string, string[]>
  ) => {
    // update the query parameters in the URL with the applied filters
    updateQueryParamsFilters({
      arraySelectedFilters,
      params: processedFilters,
      router,
      isClearAll: clearAll,
      withoutProducts
    })
    // update the state with the current applied filters
    setFilterQuery(processedFilters)

    // get all the keys from the 'applyFilters' object
    const appliedFilters = Object.keys(processedFilters)
    // check if there are any keys (filters to apply)
    const isApplyFilters = Array.isArray(appliedFilters)
    // check if any of the filter values are truthy
    const isKeyData = Object.values(applyFilters).some((value) => {
      return value
    })
    // exit early if there are no filters to apply and no truthy filter values
    if (!isApplyFilters && !isKeyData) return

    // initialize an empty array to hold the new selected filters
    const newSelectedFilters: GetData[] = []
    // cast 'initialData' to 'FacetsData' type for safer access
    const validInitialData = initialData as FacetsData
    // cast 'appliedFilters' to 'ModifiedSelectedFiltersReturnType' type for safer access
    const validApplyFilters =
      processedFilters as ModifiedSelectedFiltersReturnType
    // define pre-existent priceRangeFrom and priceRangeTo
    const priceRangeFrom = validApplyFilters['priceRangeFrom']?.[0]
    const priceRangeTo = validApplyFilters['priceRangeTo']?.[0]
    // check if priceRangeFrom and priceRangeTo are not undefined
    // and add it to the newSelectedFilters
    if (priceRangeFrom && priceRangeTo) {
      newSelectedFilters.push({
        id: `${priceRangeFrom}-${priceRangeTo}`,
        name: `${priceRangeFrom} - ${priceRangeTo}`,
        from: parseInt(priceRangeFrom),
        to: parseInt(priceRangeTo),
        icon: '',
        property: 'priceRange',
        status: 'selected'
      })
    }
    const promotionOnly = validApplyFilters['promotionsOnly']?.[0]
    if (promotionOnly) {
      newSelectedFilters.push({
        id: 'promotionsOnly',
        name: 'promotionsOnly',
        icon: '',
        property: 'promotionsOnly',
        status: 'selected',
        quantity: initialData?.promotionsOnly?.quantity
      })
    }
    // iterate through each key in 'appliedFilters'
    appliedFilters.forEach((key: keyof TFacetsDataWithCategoriesAndPrices) => {
      if (!paramsToCheck.includes(key)) return
      // validate if the key has data
      if (isValidArrayWithData(validApplyFilters[key])) {
        // Iterate through each filter under the current key
        validApplyFilters[key].forEach((filter: string) => {
          // Find the id of the current filter in the 'initialData' object
          const initialDataKeyValue = validInitialData[key] as TFacetData
          // Check if the current key is not 'priceRangeFrom' or 'priceRangeTo'
          if (key === 'priceRangeFrom' || key === 'priceRangeTo') return
          // push the new data to the newSelectedFilters
          newSelectedFilters.push({
            id: findId(initialDataKeyValue, filter),
            name: filter,
            icon: filterIconDictionary(
              findIconName(initialDataKeyValue, filter)
            ), // Get the icon using a dictionary function
            property: key,
            status: 'selected'
          })
        })
      }
    })
    // update the state with the new selected filters
    setArraySelectedFilters(newSelectedFilters)
    setHasFilters(isFilterActive(newSelectedFilters))
  }

  const handleStatusButton = (formatedData: TFormatedData[]) => {
    // Get the names of the active filters from formatedData
    const activeFilterNames = (formatedData as TFormatedData[]).flatMap(
      (filter) => {
        return filter.data
          .filter((item) => {
            return item.status === 'active'
          })
          .map((item) => {
            return item.name
          })
      }
    )
    // Check if there is a difference between the active filters and the filters in the previousActiveFacetsRef
    const isDifference =
      activeFilterNames.some((name) => {
        return !previousActiveFacetsRef.current.includes(name)
      }) ||
      previousActiveFacetsRef.current.some((name) => {
        return !activeFilterNames.includes(name)
      })

    // Check if there is a filter selected and not in the url
    // this is used when user click on "limpiar"
    const isSelectedFilterNotInQuery = previousActiveFacetsRef.current.some(
      (name) => {
        const queryString = router.asPath.split('?')[1] || ''
        const queryParams = new URLSearchParams(queryString)
        const allQueryParamValues = Array.from(queryParams.values()).flatMap(
          (value) => {
            return value.split(',').map(decodeURIComponent)
          }
        )
        return !allQueryParamValues.includes(name)
      }
    )
    setEnableButton(isDifference || isSelectedFilterNotInQuery)
  }

  // apply filters
  const handleApplyOnClick = async (callBack?: () => void) => {
    const hasEmptyFacetsData = isFacetsDataEmpty(formatData as TFormatedData[])
    const validFormatedData = hasEmptyFacetsData
      ? previousFormatedDataRef.current
      : formatData
    // get initialData formated
    const formatDataArray = validFormatedData as TFormatedData[]
    // get current selected filters
    const selectedFilters = getSelectedFilters(formatDataArray)
    const cleanedSelectedFilters = removeDuplicatesFromObject(selectedFilters)
    const processedSelectedFilters = processPriceAndPromoFacets(
      cleanedSelectedFilters
    )

    // check if selectedFilters is empty to clear the queryparams
    if (isObjectEmpty(cleanedSelectedFilters) && !clearAll) {
      setClearAll(true)
    }
    setApplyFilters(processedSelectedFilters)
    setCurrentSelectedFilters(processedSelectedFilters)
    setEnableButton(false)
    if (typeof callBack === 'function') {
      await sleep(500)
      callBack()
    }
  }

  // clear all facet filter data and url
  const handleClearOnClick = () => {
    // clearAll to invoke updateQueryParamsFilters and reset url
    setClearAll(true)
    setClearImmediately(true)
    setFormatData((prevFormatData) => {
      const hasEmptyFacetsData = isFacetsDataEmpty(
        prevFormatData as TFormatedData[]
      )
      const validFormatedData = hasEmptyFacetsData
        ? previousFormatedDataRef.current
        : prevFormatData
      return validFormatedData.map((filter) => {
        return {
          ...filter,
          isFiltering: false,
          data: filter.data.map((item) => {
            return {
              ...item,
              status: 'initial',
              data: isValidArrayWithData(item.data)
                ? (item.data as ICategory[])?.map((innerItem) => {
                    return {
                      ...innerItem,
                      selected: false
                    }
                  })
                : item.data
            }
          })
        }
      })
    })
    setArraySelectedFilters([])
    setEnableButton(true)
  }

  const handleClickOnPill = async (
    property: string,
    id: string,
    name: string
  ) => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 1279

    const hasEmptyFacetsData = isFacetsDataEmpty(formatData as TFormatedData[])
    const validFormatedData = hasEmptyFacetsData
      ? previousFormatedDataRef.current
      : formatData
    if (!isValidArrayWithData(validFormatedData)) return
    const updatedFormatData = validFormatedData.map((dataObject) => {
      if (dataObject.property !== property) return dataObject
      // Toggle status of the selected element
      const selectedElement = dataObject.data.find((item) => {
        if (item.name === 'priceRange') return item
        if (item.name === 'promotionsOnly') return item
        if (!item.data?.[0]?.['id']) {
          return item
        }
        return item.data?.[0]?.['id'] === id
      })
      if (selectedElement?.status) {
        selectedElement.status =
          selectedElement?.status !== 'initial' ? 'initial' : 'active'
      }
      // Update lead based on active elements
      dataObject.lead = createLead(
        dataObject.data
          .filter((item) => {
            return item.status === 'active'
          })
          .map((item) => {
            return item.name
          })
      )
      // Handle arraySelectedFilters for 'initial' status
      if (
        selectedElement?.status === 'initial' ||
        !isValidArrayWithData(selectedElement?.data)
      ) {
        //  this case is when you filter to much and we only have
        // selected red pills and you click on one of them
        let modifiedSelectedElement = selectedElement
        if (!isValidArrayWithData(modifiedSelectedElement?.data)) {
          modifiedSelectedElement = {
            name,
            property: modifiedSelectedElement?.property,
            status: 'initial',
            icon: '',
            id: ''
          }
        }
        // Remove the filter from the arraySelectedFilters state
        setArraySelectedFilters((prevFilters) => {
          return prevFilters.filter((filter) => {
            if (filter.property === 'priceRange') {
              return filter.property !== modifiedSelectedElement.name
            }
            return filter.name !== modifiedSelectedElement.name
          })
        })
      } else {
        // Add the filter to the arraySelectedFilters states
        setArraySelectedFilters((prevFilters) => {
          return [
            ...prevFilters,
            {
              id: selectedElement.data[0]['id'],
              name: selectedElement.name,
              icon: selectedElement.icon,
              property: dataObject.property,
              status: 'selected'
            }
          ]
        })
      }
      // this case is when user filter to much and we only have
      // selected red pills and you click on one of them
      if (!isValidArrayWithData(selectedElement?.data)) {
        return {
          ...dataObject,
          data: [
            {
              ...dataObject.data[0],
              status: 'initial' as GetDataStatus,
              name
            }
          ],
          isFiltering: false,
          lead: '',
          property: selectedElement.name as keyof FacetsData
        }
      }
      return dataObject
    })
    if (withoutProducts && !isValidArrayWithData(validFormatedData)) {
      updatedFormatData.forEach((item) => {
        if (item?.lead?.length > 0) {
          item.data[0].status = 'active'
          item.data[0].name = item?.lead
        }
      })
    }

    const activeFilters = activeFiltering(updatedFormatData)
    setFormatData(activeFilters)
    if (!isMobile) {
      // Apply immediately filters
      await sleep(500)
      handleApplyOnClick()
      return
    }
    // Update the button status
    handleStatusButton(activeFilters)
  }

  const handleOnSelectRange = async (min: number, max: number) => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 1279
    const formatedData = formatData as TFormatedData[]
    const findFilter =
      formatedData.find((facet) => {
        return facet.property === 'priceRange'
      }) || null
    if (
      typeof findFilter === 'object' &&
      isValidArrayWithData(Object.keys(findFilter))
    ) {
      const updateFilterData = formatedData.map((facet) => {
        if (facet.property === 'priceRange') {
          const isValueChanged =
            min !== priceRangeValueRef.current?.from ||
            max !== priceRangeValueRef.current?.to
          const priceRangeFacet = facet
          priceRangeFacet.data[0].status = isValueChanged ? 'active' : 'initial'
          priceRangeFacet.lead = createLead([min?.toString(), max?.toString()])
          priceRangeFacet.data[0].data[0].id = `${min}-${max}`
          priceRangeFacet.data[0].data[0].from = min
          priceRangeFacet.data[0].data[0].to = max
        }
        return facet
      })

      setFormatData(activeFiltering(updateFilterData))

      if (isMobile) {
        // Update the button status
        handleStatusButton(updateFilterData)
        return
      }
      // Apply immediately filters
      await sleep(500)
      handleApplyOnClick()
    }
  }

  const handleOnTogglePromotions = async (isActive: boolean) => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 1279
    // Get formatted data
    const formatedData = formatData as TFormatedData[]

    // Find the promotions filter
    const findFilter =
      formatedData.find((facet) => {
        return facet.property === 'promotionsOnly'
      }) || null

    // Check if the found filter is valid
    if (
      typeof findFilter === 'object' &&
      isValidArrayWithData(Object.keys(findFilter))
    ) {
      // Update the filter data
      const updateFilterData = formatedData.map((facet) => {
        if (facet.property === 'promotionsOnly') {
          // Update the filter status
          facet.data[0].status = isActive ? 'active' : 'initial'
          facet.data[0].data[0].id = isActive ? 'promotionsOnly' : 'all'
          facet.data[0].data[0].isActive = isActive ? 'active' : 'initial'
        }
        return facet
      })

      // Update state with modified data
      setFormatData(activeFiltering(updateFilterData))

      if (isMobile) {
        // Update the button status
        handleStatusButton(updateFilterData)
        return
      }
      // Apply immediately filters
      await sleep(500)
      handleApplyOnClick()
    }
  }

  // processInitialData
  // This hook triggers when 'initialData', 'router.asPath' or 'filterQuery' changes,
  // and processes the initial data to update the state and format
  // the data accordingly for the current router path.
  useEffect(() => {
    processInitialDataRef.current++
    processInitialData(filterQuery, processInitialDataRef.current)
  }, [initialData, filterQuery, withoutProducts, router?.asPath]) // Hook dependencies: re-run whenever 'initialData', 'router.asPath' or 'filterQuery' changes

  // handleProcessedFilters
  // This hook is triggered whenever the 'applyFilters' object changes
  // It processes the filters, maps them to a new structure,
  // and updates the selected filters and query parameters accordingly.
  useEffect(() => {
    // Initialize an empty object to hold the filters parsed from the query string.
    let applyFiltersFromQueryString = {}

    // Check if the applyFilters object is empty using a utility function isObjectEmpty
    // if it is then user is comming directly from url
    if (isObjectEmpty(applyFilters)) {
      // Get the query object from the router, or an empty object if router or router.query is undefined.
      const query = router?.query || {}

      // Transform the query object into the applyFiltersFromQueryString object.
      applyFiltersFromQueryString = Object.keys(query).reduce((acc, key) => {
        // Check if the current query parameter is a string. If it is, split it by commas
        // and decode each part. If it's already an array, just decode each element.
        const values =
          typeof query[key] === 'string'
            ? (query[key] as string).split(',').map(decodeURIComponent) // Split the string into an array by commas and decode.
            : (query[key] as string[]).map(decodeURIComponent) // Decode each string in the array.

        // Assign the decoded values to the accumulator object under the key.
        acc[key] = values
        return acc
      }, {})
      const formatFacetFromQueryParams = formatFiltersFromQueryParams(
        previousInitialDataRef,
        processPriceAndPromoFacets(applyFiltersFromQueryString)
      )

      setCurrentSelectedFilters(formatFacetFromQueryParams)
    }

    // Determine which filters to use: if applyFilters is empty, use the filters parsed from the query string;
    // otherwise, use applyFilters as it is.
    const processedFilters = isObjectEmpty(applyFilters)
      ? formatFiltersFromQueryParams(
          previousInitialDataRef,
          applyFiltersFromQueryString
        )
      : applyFilters
    // process the filters state or url filters
    handleProcessedFilters(processPriceAndPromoFacets(processedFilters))
  }, [initialData, applyFilters, router?.asPath]) // Hook dependency: re-run whenever 'initialData' or 'applyFilters' changes

  useEffect(() => {
    setHasFilters(isFilterActive(arraySelectedFilters))
  }, [arraySelectedFilters])

  // after setting clearAll to true, we need to set it to false
  // to avoid the url to be reseted on user interaction
  // after 800 ms
  useEffect(() => {
    const sleepClearAll = async () => {
      await sleep(800)
      setClearAll(false)
      setClearImmediately(false)
    }

    if (clearAll) {
      sleepClearAll()
    }
    if (clearImmediately) {
      handleApplyOnClick()
    }
  }, [clearAll, clearImmediately])

  return {
    initialData,
    applyFilters: transformFacetsDataString(
      applyFilters
    ) as ModifiedSelectedFiltersReturnType,
    currentSelectedFilters: transformFacetsDataString(
      currentSelectedFilters as ModifiedSelectedFiltersReturnType
    ) as ModifiedSelectedFiltersReturnType,
    filterData: {
      ...facets,
      arraySelectedFilters,
      enableButton,
      filtersQuantity,
      hasFilter: hasFilters,
      priceRangeValueRef: priceRangeValueRef.current,
      handleApplyOnClick,
      handleClearOnClick,
      handleClickOnPill,
      handleOnSelectRange,
      handleOnTogglePromotions,
      setWithoutProducts
    },
    compareAndSetInitialData
  }
}
