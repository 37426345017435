import {
  FacetFilters,
  FiltersProps,
  OrderByRadioButtonList,
  SelectByOptions,
  Tabs
} from '@smu-chile/pkg-unimarc-components'
import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import { Dispatch, SetStateAction, useEffect } from 'react'
import styles from './FacetsAndOrderByFiltersContent.module.css'

const TAB_TITLES = {
  facets: 'Filtrar',
  orderBy: 'Ordenar'
}

export type TFacetsAndOrderByFiltersContentProps = {
  orderByProps: SelectByOptions
  facetFilterProps: FiltersProps
  isMobile: boolean
  onClickDetectTab?: Dispatch<SetStateAction<number>>
}

const allowedFilters = [
  'brands',
  'container',
  'foodWorld',
  'priceRange',
  'volume',
  'warningStamps'
]

const checkForValidFacets = (
  facetFilterData: FiltersProps,
  allowedFilters: string[]
) => {
  return Object.entries(facetFilterData || {}).some(([key]) => {
    return allowedFilters.includes(key)
  })
}

export const FacetsAndOrderByFiltersContent = ({
  orderByProps,
  facetFilterProps,
  isMobile,
  onClickDetectTab
}: TFacetsAndOrderByFiltersContentProps): React.ReactElement => {
  const getTitles = (): string[] => {
    const titles: string[] = []

    const addTitleIfValid = (
      data: typeof orderByProps | typeof facetFilterProps,
      title: string
    ) => {
      const orderByData = data as typeof orderByProps
      const facetFilterData = data as typeof facetFilterProps
      const validFacets = checkForValidFacets(facetFilterData, allowedFilters)
      if (isValidArrayWithData(orderByData?.options) || validFacets) {
        titles.push(title)
      } else {
        // Return true if we need to add an empty string later
        return true
      }
      return false
    }

    // Track if we need to add an empty string
    let needEmptyString = false

    // Add titles for options with products
    if (addTitleIfValid(orderByProps, TAB_TITLES.orderBy)) {
      needEmptyString = true
    }
    if (addTitleIfValid(facetFilterProps, TAB_TITLES.facets)) {
      needEmptyString = true
    }

    // Add empty string at the second position if needed
    if (needEmptyString) {
      titles.splice(1, 0, '') // Add empty string at index 1 (second position)
    }
    return titles.reverse()
  }

  const getTabs = (): Array<React.ReactChild> => {
    const tabs: Array<React.ReactChild> = []

    const addTabIfValid = (
      data: typeof orderByProps | typeof facetFilterProps
    ) => {
      const orderByData = data as typeof orderByProps
      const facetFilterData = data as typeof facetFilterProps
      if (isValidArrayWithData(orderByData?.options)) {
        return tabs.push(
          <OrderByRadioButtonList
            onClick={orderByData?.onClick}
            options={orderByData?.options}
            selected={orderByData?.selected}
          />
        )
      }
      if (facetFilterData?.foodWorld) {
        return tabs.push(
          <FacetFilters
            {...facetFilterData}
            isMobile={isMobile}
          />
        )
      }
      return null
    }

    addTabIfValid(facetFilterProps)
    addTabIfValid(orderByProps)

    return tabs
  }

  useEffect(() => {
    return () => {
      onClickDetectTab(0)
    }
  }, [onClickDetectTab])

  return (
    <>
      <Tabs
        callbackOnTabClick={onClickDetectTab}
        customBorderWidth={true}
        customTabClassName={styles.customTab}
        disableWithOneOption={Boolean(getTabs().length === 1)}
        tabs={getTabs()}
        titles={getTitles()}
        titlesAccentFontWeight='semibold'
        titlesFontWeight='regular'
      />
    </>
  )
}
